// eslint-disable-next-line import/no-unresolved
import { CloudAppSelection, CloudStreamOptions } from '@zerolight-core/libzl/types/src/globals';

import {
	createContext,
	useContext,
	useState,
	useEffect,
	useCallback,
	PropsWithChildren,
} from 'react';

export type OptionalStreamOpts = {
	cloudConnectionParameters: { customer: string; renderService: string };
	appSelect?: CloudAppSelection;
	consumeMouseClicks?: boolean;
	fakeMouseWithTouch?: boolean;
};

interface ICloudStreamOptionsContext {
	options?: CloudStreamOptions;
	ready: boolean;
	updateStreamOptions: (options: OptionalStreamOpts) => void;
}

export const CloudStreamOptionsContext = createContext<ICloudStreamOptionsContext>({
	options: undefined,
	ready: false,
	updateStreamOptions: () => null,
});
export const useCloudStreamOptions = () => useContext(CloudStreamOptionsContext);

export const CloudStreamOptionsProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [token, setToken] = useState<string | undefined>();
	const [connectionOptions, setConnectionOptions] = useState<CloudStreamOptions>({
		cloudConnectionParameters: {
			customer: '',
			renderService: '',
			environment: '',
			region: '',
		},
		streamingMode: 'video',
		parent: 'streamContainer',
		directConnection: false,
		consumeMouseClicks: false,
		fakeMouseWithTouch: false,
	});

	const updateStreamOptions = useCallback(
		(options: OptionalStreamOpts) => {
			setConnectionOptions({
				...connectionOptions,
				cloudConnectionParameters: {
					...connectionOptions.cloudConnectionParameters,
					...options.cloudConnectionParameters,
				},
				appSelect: options.appSelect || undefined,
				consumeMouseClicks: options.consumeMouseClicks || false,
				fakeMouseWithTouch: options.fakeMouseWithTouch || false,
			});
		},
		[connectionOptions],
	);
	const tokenRequired = [''].includes(connectionOptions.cloudConnectionParameters.renderService);
	const requestToken = useCallback(async () => {
		if (tokenRequired) {
			// eslint-disable-next-line no-console
			console.log(
				`TODO: provide a token for ${connectionOptions.cloudConnectionParameters.renderService}`,
			);

			setToken('');
		}
	}, [tokenRequired, connectionOptions.cloudConnectionParameters.renderService, setToken]);

	useEffect(() => {
		requestToken();
	}, [requestToken]);

	const ready = !!(
		(!tokenRequired || token) &&
		connectionOptions.cloudConnectionParameters.renderService
	);

	return (
		<CloudStreamOptionsContext.Provider
			value={{ options: connectionOptions, ready, updateStreamOptions }}
		>
			{children}
		</CloudStreamOptionsContext.Provider>
	);
};
